
.brand-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 600px;
  }
  
  .brand-item {
    display: flex;
    align-items: center;
  }
  
  .brand-item input[type="checkbox"] {
    display: none;
  }
  
  .brand-item label {
    text-align: left;
    font-size:1.3em;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
  }
  
  .brand-item label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-radius: 3px;
  }
  
  .brand-item input[type="checkbox"]:checked + label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 9px;
    width: 12px;
    height: 12px;
    background-color: #fce501;
    border-radius: 2px;
  }
  .button-skip{
    margin-left:10px;
  }