.contact-info-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    max-width: 500px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group-full {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
}

.form-group label,
.form-group-full label {
    text-align: left;
    margin-bottom: 5px;
    align-items: center;
    color: white;
    /* Make label text white */
}

.form-group input,
.form-group-full input {
    font-family: 'camel', sans-serif;
    padding: 10px;
    font-size: 0.7em;
    width: 100%;
    box-sizing: border-box;
    background: transparent;
    border: 2px solid white;
    color: white;
    border-radius: 8px;
    transition: background 0.3s ease, border 0.3s ease;
    /* Smooth transition for background and border */
}

.form-group input:focus,
.form-group-full input:focus {
    border: 2px solid white;
    /* Remove border effect on focus */
    background: rgba(29, 1, 131, 0.5);
    /* Add background with 50% opacity on focus */
    outline: none;
    /* Remove default outline */
}

.radio-group {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.radio-group label {
    margin-bottom: 20px;
    display: flex;
    color: white;
    /* Make label text white */
}

.radio-group input {
    display: none;
    /* Hide the default radio button */
}

.radio-group input+span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid white;
    /* 2px white border */
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    margin-right: 10px;
}

.radio-group input:checked+span {
    background-color: #fce50100;
    /* Yellow fill when selected */
}

.radio-group input+span::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: transparent;
    /* Transparent dot */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.radio-group input:checked+span::after {
    background-color: #fce501;
    /* Yellow dot when selected */
}

.center-button {
    display: flex;
    justify-content: center;
    grid-column: 1 / span 2;
}