.terms-content {
    margin-bottom: 20px;
    /* Set a maximum height for the terms content */
    overflow-y: auto;
    /* Add scroll if the content overflows */
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    /* Semi-transparent background */
    border-radius: 5px;
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
}

.checkbox-container input {
    margin-right: 10px;
}

.checkbox-container {
    display: flex;
    align-items: center;
}

.checkbox-container input[type="checkbox"] {
    display: none;
}

.checkbox-container label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
    text-align: left;
    font-size: 0.8em;
}

.checkbox-container label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-radius: 3px;
}

.checkbox-container input[type="checkbox"]:checked+label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 4px;
    width: 12px;
    height: 12px;
    background-color: #fce501;
    border-radius: 2px;
}

.terms-content {
    margin-bottom: 20px;
    overflow-y: auto;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}


/* Custom Scrollbar Styling */

.terms-content::-webkit-scrollbar {
    width: 12px;
    /* Adjust the width of the scrollbar */
}

.terms-content::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    /* Background of the scrollbar track */
    border-radius: 5px;
    /* Rounded corners for the track */
}

.terms-content::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    /* Color of the scrollbar thumb */
    border-radius: 5px;
    /* Rounded corners for the thumb */
    border: 3px solid rgba(255, 255, 255, 0.3);
    /* Border around the thumb */
}

.terms-content::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.7);
    /* Color when hovering over the scrollbar thumb */
}

.floating-image {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 8vh;
    height: auto;
    pointer-events: none;
}

.chat-ligth-1 {
    transform: rotate(-20deg);
    top: 5%;
    left: 5%;
}

.message-ligth-1 {
    top: 10%;
    right: 10%;
}

.like-dark-1 {
    transform: rotate(350deg);
    bottom: 40%;
    left: 0%;
}

.like-dark-2 {
    transform: rotate(20deg);
    bottom: 15%;
    right: 15%;
}

.message-dark-1 {
    bottom: 15%;
    left: 15%;
}

a.terms-content {
    color: white;
}