.page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 40px);
    text-align: center;
    color: #fff;
    /* Adjust based on your background image */
}

.pin-display {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.pin-slot {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    /* Adjust width as needed */
    height: 40px;
    /* Adjust height as needed */
    margin: 0 5px;
}

.underscore {
    font-size: 2em;
    color: #fff;
    display: block;
    width: 100%;
    /* Makes the underline as wide as the pin-slot */
    height: 3px;
    /* Height of the underline */
    background-color: #fff;
    margin-top: 20px;
    /* Adjust as needed to position the line */
}

.dot {
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
}

.numpad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 20px;
}

.numpad button {
    font-size: 2em;
    cursor: pointer;
    background: none;
    border: solid white 3px;
    aspect-ratio: 1/1;
    border-radius: 12px;
    color: #fce501;
    height: 7vh;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.numpad button:focus {
    outline: none;
}

.zero-button {
    grid-column: 2;
}