.slot-machine {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.reels {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    height: 80%;
    /* Adjust this value based on how much height you want to allocate to the reels */
}

.reel {
    flex: 1;
    overflow: hidden;
    border: 4px solid #1972b9;
    margin: 0 5px;
    position: relative;
    border-radius: 8px;
    box-shadow: #0000004a 10px 10px 5px;
    aspect-ratio: 5 / 12;
}

.reel-inner {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: -30%;
    /* Adjust this value based on how you want the symbols to be positioned */
    left: 0;
    right: 0;
    height: auto;
}

.symbol {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 33.33%;
    /* Assuming you want three symbols to be visible at a time */
    /* height and width of each image */
}

.symbol img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    /* Maintain aspect ratio */
}

.result-buttons {
    display: flex;
    gap: 10px;
}

.spin-button,
.result-buttons button {
    padding: 10px 20px;
    font-size: 1.2em;
    background-color: #fff;
    border: 2px solid #16A085;
    color: #16A085;
    cursor: pointer;
    transition: all 0.3s ease;
}

.spin-button:hover,
.result-buttons button:hover {
    background-color: #16A085;
    color: #fff;
}

.result {
    margin-top: 20px;
    font-size: 1.5em;
    color: white;
}

.swipe-cue {
    margin-top: 20px;
    font-size: 1.2em;
    color: #fff;
    text-transform: uppercase;
}

.reel-sticker {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #019ee1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swipe-down {
    width: 30px;
    margin-top: 40px;
}

.floating-image {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 6vh;
    height: auto;
    pointer-events: none;
}

.chat-ligth-1 {
    transform: rotate(-20deg);
    top: 5%;
    left: 5%;
}

.message-ligth-2 {
    top: -2%;
    right: 50%;
}

.message-ligth-1 {
    top: 10%;
    right: 10%;
}

.like-dark-1 {
    transform: rotate(350deg);
    bottom: 30%;
    left: -5%;
}

.like-dark-2 {
    transform: rotate(30deg);
    bottom: 15%;
    right: 25%;
}

.message-dark-1 {
    bottom: 10%;
    left: 18%;
}

.message-dark-2 {
    transform: rotate(-20deg);
    bottom: 25%;
    right: 5%;
}

.slot .page-header,
.slot .page-content,
.slot .page-footer {
    width: calc(100% - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-header {
    flex-direction: column;
    margin-bottom: 10px;
}


/* .slot .page-title-small {
    font-size: 2em;
}

.slot .page-title-large {
    font-size: 4em;
}

@media (max-width: 600px) {
    .slot .page-title-small {
        font-size: 1.5em;
    }
    .slot .page-title-large {
        font-size: 3em;
    }
} */

.page-footer {
    flex-direction: column;
    margin-top: 10px;
}

.reel-inner .symbol {
    box-sizing: border-box;
}

.result-buttons,
.swipe-cue {
    font-size: 1.8em;
}

@media (max-width: 600px) {
    .result-buttons,
    .swipe-cue {
        font-size: 1.3em;
    }
}