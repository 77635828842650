/* Admin.css */
.page-container {
    padding: 20px;
  }
  
  .admin {
    max-width: 600px;
    margin: auto;
    overflow: scroll;
  }
  
  .admin h2 {
    margin-bottom: 20px;
  }
  
  .admin div {
    margin-bottom: 15px;
  }
  
  .admin label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .admin input[type="text"],
  .admin input[type="number"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .admin button {
    padding: 10px 20px;
    margin-right: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .admin button:hover {
    background-color: #0056b3;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  table th,
  table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  table th {
    background-color: #f0f0f0;
  }
  .back-button {
    margin-bottom: 20px;
    background-color: #28a745; /* Green color */
  }
  
  .back-button:hover {
    background-color: #218838; /* Darker green on hover */
  }