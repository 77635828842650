.success {
    text-align: center;
    padding: 20px;
}

.rating-stars {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.rating-star {
    width: 10vh;
    height: 10vh;
    cursor: pointer;
}