@font-face {
    font-family: 'camel';
    src: url('./assets/fonts/CAMEL-BOLD.woff') format('woff2'), url('./assets/fonts/CAMEL-BOLD.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'graphik';
    src: url('./assets/fonts/GRAPHIK-BOLD.woff2') format('woff2'), url('./assets/fonts/GRAPHIK-BOLD.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


/* You can add more @font-face rules for additional fonts */


/* Optionally, you can define font-family globally or for specific elements */

body {
    user-select: none;
    /* Standard */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    background: rgb(0, 0, 88);
    background: linear-gradient(180deg, #009be0 2%, #009be0 32%, rgba(9, 9, 121, 1) 100%);
    font-family: 'camel', sans-serif;
}

h1,
h2,
h3,
h4 {
    font-family: 'camel', sans-serif;
    color: #fce501;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    text-transform: uppercase;
    margin: 0;
}

.page-title-large {
    font-size: 3.5em;
    line-height: 50px;
}

.page-title-small {
    font-size: 1.8em;
}

.page-header {
    height: calc(25vh - 20px);
    display: flex;
    flex-direction: column;
    justify-content: end;
    text-transform: uppercase;
}

.page-footer {
    justify-content: start;
    height: calc(25vh - 20px);
    text-transform: uppercase;
}

body,
html,
#root {
    user-drag: none;
    -webkit-user-drag: none;
    min-height: 100vh;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
}


/* #root {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
} */

#root {
    position: relative;
    /* Other styles for the #root element */
}

#root::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('./assets/images/BgPattern.png');
    mix-blend-mode: multiply;
    z-index: -1;
    /* Ensures the pseudo-element is behind the content of #root */
}

.admin {
    padding: 20px;
    text-align: left;
    background: #000000a8;
}


/* You can also define classes for specific usage */

.custom-font-camel {
    font-family: 'camel', sans-serif;
}

.custom-font-graphik {
    font-family: 'graphik', sans-serif;
}

body {
    margin: 0;
    font-family: 'graphik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* Add global styles to ensure the app content takes full height */

.App {
    display: flex;
    flex-direction: column;
}

.navbar {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.navbar li {
    display: inline;
}

.navbar a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 20px;
    text-decoration: none;
}

.navbar a:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.page-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
    text-align: center;
    z-index: 1;
    color: #fff;
    /* Adjust based on your background image */
}

.centered-page h1 {
    font-size: 2.5em;
}

.centered-page p {
    font-size: 1.2em;
}

.centered-page button {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
}

.page-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 50vh;
    font-size: 1.2em;
    max-width: 600px;
}

.logo {
    max-width: 40vw;
    max-height: 20vh;
    width: 100%;
    height: auto;
}

.page-button {
    font-size: 1.8em;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 40px;
    border: none;
    color: #1f72b9;
    font-family: 'camel', sans-serif;
}

.page-title-large {
    font-size: 6em;
    line-height: 80px;
}

.page-title-small {
    font-size: 3em;
}

.page-title {
    font-size: 4.5em;
}

@media (max-width: 600px) {
    .page-button {
        font-size: 1.3em;
        border-radius: 24px;
    }
    .page-title-large {
        font-size: 3.5em;
        line-height: 50px;
    }
    .page-title-small {
        font-size: 1.8em;
    }
    .page-title {
        font-size: 2.2em;
    }
}